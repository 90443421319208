import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import pl from "./translations/common/pl.json";
import en from "./translations/common/en.json";
import sk from "./translations/common/sk.json";
import cz from "./translations/common/cz.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: { common: en },
      pl: { common: pl },
      sk: { common: sk },
      cz: { common: cz },
    },
  });

export default i18n;
