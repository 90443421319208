import { ReactComponent as CzechFlag } from './czech-flag.svg';
import { ReactComponent as EnglandFlag } from './england-flag.svg';
import { ReactComponent as PolandFlag } from './poland-flag.svg';
import { ReactComponent as SlovakiaFlag } from './slovakia-flag.svg';

const Icons = {
    CzechFlag,
  EnglandFlag,
  PolandFlag,
  SlovakiaFlag}  as const;

  export type TIcons = keyof typeof Icons;
  
  export default Icons;
  